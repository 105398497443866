import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {asLink} from "@pg-design/helpers-css";
import {LockOutlineIcon} from "@pg-design/icons-module";

import {InquiryButtonTypeName} from "../../../offer/detail/constants/inquiry_button_type";
import {PropertyListIconButton} from "../PropertyListIconButton";

interface IProps {
    onClick?: (event: React.MouseEvent) => void;
}

export const ReservedButton: React.FC<IProps> = (props) => {
    const {onClick} = props;

    const theme = useTheme();

    const onButtonClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClick && onClick(event);
    };

    return (
        <PropertyListIconButton
            onClick={onButtonClick}
            css={reservationButtonStyle}
            icon={<LockOutlineIcon size="1.2" wrapperColor={theme.colors.danger} wrapperSize="2" wrapperType="circle" fill="#fff" />}
        >
            {InquiryButtonTypeName.RESERVATION}
        </PropertyListIconButton>
    );
};

//  Styles
const reservationButtonStyle = (theme: Theme) => css`
    //  TODO: Find a way to not pass a theme
    ${asLink(theme)};
    font-weight: 500;
`;
