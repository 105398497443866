import React, {ReactNode} from "react";
import {css} from "@emotion/react";

import {mr, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

export interface IPropertyListIconButtonProps {
    children: ReactNode;
    icon: ReactNode;
    onClick?: (event: React.MouseEvent) => void;
    className?: string;
}

export function PropertyListIconButton(props: IPropertyListIconButtonProps) {
    const {children, icon, className, onClick} = props;

    return (
        <div css={propertyListIconButton} className={className}>
            <div css={mr()}>{icon}</div>
            <div css={textWrapper} onClick={onClick}>
                <Text variant="body_copy_2">
                    <span data-testid="icon-button-text">{children}</span>
                </Text>
            </div>
        </div>
    );
}

//  Styles
export const propertyListIconButton = css`
    height: 100%;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    ${w100};
`;

const textWrapper = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
