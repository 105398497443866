import React, {Fragment, useMemo} from "react";

import {Button} from "@pg-design/button";
import {w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {priceFormat} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {InquiryButtonTypeName} from "../../../offer/detail/constants/inquiry_button_type";
import {OfferPriceType} from "../../../offer/types/OfferPriceType";

interface IProps {
    twoPercentCreditAvailable?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    variant?: "tile";
    isPlansList?: boolean;
    isInPropertyConfigurator?: boolean;
    showPrice?: boolean;
    price?: number | null;
    priceType?: number | null;
    currency: string;
}

export const AskForPropertyButton: React.FC<IProps> = (props) => {
    const {isMobile} = useUserDevice();

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClick && props.onClick(event);
    };

    const buttonText = useMemo(() => {
        if (!!props.price) {
            if (props.showPrice && !props.isPlansList) {
                const priceType = props.priceType === OfferPriceType.NETTO ? " netto" : "";
                return priceFormat(props.price, {unit: `${props.currency}${priceType}`});
            }

            if (props.isInPropertyConfigurator || props.showPrice) {
                return InquiryButtonTypeName.OFFER;
            }
        }
        if (props.isInPropertyConfigurator) {
            return InquiryButtonTypeName.REVEAL_PRICE;
        }
        if (isMobile) {
            return InquiryButtonTypeName.REVEAL;
        }
        return InquiryButtonTypeName.REVEAL_PRICE;
    }, []);

    return (
        <Fragment>
            {/* Due to CU-8693ecg7r temporary commented - program "Kredyt 2%" has ended at the begging of 2024 */}
            {/*{props.twoPercentCreditAvailable && !props.isPlansList && (*/}
            {/*    <div css={[flexAbsoluteCenter, mb(0.5)]}>*/}
            {/*        <MegaphoneIcon css={mr()} size="2" /> <Text variant="info_txt_1">Kredyt 2%</Text>*/}
            {/*    </div>*/}
            {/*)}*/}

            {props.isPlansList || props.isInPropertyConfigurator ? (
                <Button variant="filled_primary" size="x-small" onClick={onClick} css={w100}>
                    <Text variant="button_small">{buttonText}</Text>
                </Button>
            ) : (
                <Button onClick={onClick} variant="highlight_primary">
                    {buttonText}
                </Button>
            )}
        </Fragment>
    );
};
